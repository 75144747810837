import React from "react";
import ReactDOM from "react-dom";
import App from "./App.js";

import { Workbox } from "workbox-window";
import CacheBuster from "./CacheBuster";
import MetaJson from "./prefetchdata/meta.json";

if (!navigator.cookieEnabled) {
  alert("You need to enable cookies in order to load this site.");
}

//Cache and LocalStorage Conditions
let metaData = MetaJson.version;
if (!localStorage.getItem("version")) {
  localStorage.setItem("version", metaData);
}
if (metaData !== localStorage.getItem("version")) {
  localStorage.removeItem("language");
  localStorage.removeItem("country");
  localStorage.removeItem("currency");
  localStorage.removeItem("compareItemDetails");
  localStorage.removeItem("userLocation");
  /*   localStorage.removeItem("userLocationGoogleAPI"); */
  /*   localStorage.removeItem("wishList"); */
  localStorage.removeItem("compareList");
  localStorage.removeItem("recentViewItems");
  localStorage.setItem("version", metaData);
}

if ("serviceWorker" in navigator) {
  const wb = new Workbox("/sw.js");

  wb.addEventListener("installed", event => {
    console.log("Instelled event", event);
    if (event.isUpdate) {
      console.log("Instelled event IS UPDATE", event.isUpdate);
      window.location.reload();
    }
  });
  //wb.register().then(initialiseState);
  wb.register();
}

ReactDOM.render(
  <CacheBuster>
    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
      if (loading) return null;
      if (!loading && !isLatestVersion) {
        // You can decide how and when you want to force reload
        refreshCacheAndReload();
      }

      return <App />;
    }}
  </CacheBuster>,
  document.getElementById("root")
);
